export const Environment = () => {
  return process.env.NEXT_PUBLIC_DOMAIN?.includes('localhost') ||
    process.env.NEXT_PUBLIC_DOMAIN?.includes('sandpit') ||
    process.env.NEXT_PUBLIC_DOMAIN?.includes('wpenginepowered')
    ? true
    : false;
};

export const isProdBuild = () => {
  return process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ? true : false;
}

export const isDevBuild = () => {
  return process.env.NEXT_PUBLIC_VERCEL_ENV !== "production" && process.env.NEXT_PUBLIC_VERCEL_ENV !== "preview";
}