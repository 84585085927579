import { Banner } from '@/utilities/types/Banners';
import { useCriticalBanner } from '@/utilities/contexts/CriticalBanner';
import { useNonCriticalBanner } from '@/utilities/contexts/NonCriticalBanner';
import { useState, useEffect } from 'react';

const BANNERS = ['critical', 'non-critical'] as const;

export const useBanners = () => {
  const [banners, setBanners] = useState<Banner[]>([]);

  const criticalBannerCookie = useCriticalBanner();
  const nonCriticalBannerCookie = useNonCriticalBanner();

  useEffect(() => {
    const loadBanners = async () => {
      await Promise.all(
        BANNERS.map(async (bannerId) => {
          const shouldLoad = bannerId === 'critical' ? !criticalBannerCookie : !nonCriticalBannerCookie;

          if (!shouldLoad) return;

          if(!process.env.NEXT_PUBLIC_BANNERS_PATH) return;

          try {

            const bannerResponse = await fetch(`/api/banners/${bannerId}`);
            
            if (!bannerResponse.ok) {
              console.log(`Failed to fetch banner data for ${bannerId}`)
              return;
            }

            const banner = await bannerResponse.json();

            if (banner) {
              setBanners((prev) => {
                if (!prev.some((b) => b.id === bannerId)) {
                  return [
                    ...prev,
                    {
                      id: bannerId,
                      data: banner,
                    },
                  ];
                }
                return prev;
              });
            }
          } catch (error) {
            console.error(error);
          }
        })
      );
    };

    if (!criticalBannerCookie) {
      loadBanners();
    }
  }, [criticalBannerCookie]);

  return banners;
};
